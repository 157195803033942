import React from "react";

// RO
import towersOfHanoiRO from '../../src/images/2.1_Exercise_6.1_RO.svg'
import chickenCrossing1RO from '../../src/images/2_1_chicken-crossing-1_RO.svg'
// ES
import towersOfHanoiES from '../../src/images/2.1_Exercise_6.1_ES.svg'
import chickenCrossing1ES from '../../src/images/2_1_chicken-crossing_1_ES.svg'
// PT
import towersOfHanoiPT from '../../src/images/2.1_Exercise_6.1_PT.svg'
import chickenCrossing1PT from '../../src/images/2_1_chicken-crossing-1_PT.svg'
// HU
import towersOfHanoiHU from "../../src/images/2.1_Exercise_6.1_HU.svg";
import chickenCrossing1HU from "../../src/images/2_1_chicken-crossing-1_HU.svg";
// NL
import towersOfHanoiNL from "../../src/images/Exercise-6_1_NL.svg";
import chickenCrossing1NL from "../../src/images/2_1_chicken-crossing-1_NL.svg";
// CS
import towersOfHanoiCS from "../../src/images/2.1_Exercise_6.1_CS.svg";
import chickenCrossing1CS from "../../src/images/2_1_chicken-crossing-1_CS.svg";
// HR
import towersOfHanoiHR from "../../src/images/2.1_Exercise_6.1_HR.svg";
import chickenCrossing1HR from "../../src/images/2_1_chicken-crossing-1_HR.svg";
// MT
import towersOfHanoiMT from "../../src/images/2.1_Exercise_6.1_MT.svg";
import chickenCrossing1MT from "../../src/images/2_1_chicken-crossing-1_MT.svg";
// IT
import towersOfHanoiIT from "../../src/images/2.1_Exercise_6.1_IT.svg";
import chickenCrossing1IT from "../../src/images/2_1_chicken-crossing-1_IT.svg";
// DA
import towersOfHanoiDA from "../../src/images/2.1_Exercise_6.1_DA.svg";
import chickenCrossing1DA from "../../src/images/2_1_chicken-crossing-1_DA.svg";
// LT
import towersOfHanoiLT from "../../src/images/Exercise_6.1_LT.svg";
import chickenCrossing1LT from "../../src/images/2_1_chicken-crossing-1_LT.svg";
// LV
import towersOfHanoiLV from "../../src/images/2.1_Exercise_6.1_LV.svg";
import chickenCrossing1LV from "../../src/images/2_1_chicken-crossing_1_LV.svg";
// SK
import towersOfHanoiSK from "../../src/images/2.1_Exercise_6.1_SK.svg";
import chickenCrossing1SK from "../../src/images/2_1_chicken-crossing-1_SK.svg";
// SL
import towersOfHanoiSL from "../../src/images/2.1_Exercise_6.1_SL.svg";
import chickenCrossing1SL from "../../src/images/2_1_chicken-crossing-1_SL.svg";
// BG
import towersOfHanoiBG from "../../src/images/2.1_Exercise_6.1_BG.svg";
import chickenCrossing1BG from "../../src/images/2_1_chicken-crossing-1_BG.svg";
// FR
import chickenCrossing1FR from "../../src/images/2_1_chicken-crossing-1_FR.svg";
// PL
import chickenCrossing1PL from "../../src/images/2_1_chicken-crossing-1_PL.svg";
import towersOfHanoiPL from "../../src/images/2.1_Exercise_6.1_PL.svg";
// GA
import chickenCrossing1GA from "../../src/images/2_1_chicken-crossing-1_GA.svg";
import towersOfHanoiGA from "../../src/images/2.1_Exercise_6.1_GA.svg";
// EL
import towersOfHanoiEL from "../../src/images/2.1_Exercise_6.1_EL.svg";
import chickenCrossing1EL from "../../src/images/2_1_chicken-crossing-1_EL.svg";
import chickenCrossing2EL from "../../src/images/2_1_chicken-crossing-2_EL.svg";
import chickenCrossing3EL from "../../src/images/2_1_chicken-crossing-3_EL.svg";
import minMaxEL from "../../src/images/exercise7-2_EL.svg";
// IS
import towersOfHanoiIS from "../../src/images/2.1_Exercise_6.1_IS.svg";


const staticImages = () => (
  <div style={{ display: "none" }}>
    {/* HU */}
    <img src={towersOfHanoiHU} alt="" />
    <img src={chickenCrossing1HU} alt="" />
    {/* NL */}
    <img src={towersOfHanoiNL} alt="" />
    <img src={chickenCrossing1NL} alt="" />
    {/* CS */}
    <img src={towersOfHanoiCS} alt="" />
    <img src={chickenCrossing1CS} alt="" />
    {/* HR */}
    <img src={towersOfHanoiHR} alt="" />
    <img src={chickenCrossing1HR} alt="" />
    {/* RO */}
    <img src={towersOfHanoiRO} alt="" />
    <img src={chickenCrossing1RO} alt="" />
    {/* RO */}
    <img src={towersOfHanoiRO} alt="" />
    <img src={chickenCrossing1RO} alt="" />
    {/* ES */}
    <img src={towersOfHanoiES} alt="" />
    <img src={chickenCrossing1ES} alt="" />
    {/* PT */}
    <img src={towersOfHanoiPT} alt="" />
    <img src={chickenCrossing1PT} alt="" />
    {/* MT */}
    <img src={towersOfHanoiMT} alt="" />
    <img src={chickenCrossing1MT} alt="" />
    {/* IT */}
    <img src={towersOfHanoiIT} alt="" />
    <img src={chickenCrossing1IT} alt="" />
    {/* DA */}
    <img src={towersOfHanoiDA} alt="" />
    <img src={chickenCrossing1DA} alt="" />
    {/* LT */}
    <img src={towersOfHanoiLT} alt="" />
    <img src={chickenCrossing1LT} alt="" />
    {/* LV */}
    <img src={towersOfHanoiLV} alt="" />
    <img src={chickenCrossing1LV} alt="" />
    {/* EL */}
    <img src={towersOfHanoiEL} alt="" />
    <img src={chickenCrossing1EL} alt="" />
    <img src={chickenCrossing2EL} alt="" />
    <img src={chickenCrossing3EL} alt="" />
    <img src={minMaxEL} alt="" />
    {/* SK */}
    <img src={towersOfHanoiSK} alt="" />
    <img src={chickenCrossing1SK} alt="" />
    {/* SL */}
    <img src={towersOfHanoiSL} alt="" />
    <img src={chickenCrossing1SL} alt="" />
    {/* BG */}
    <img src={towersOfHanoiBG} alt="" />
    <img src={chickenCrossing1BG} alt="" />
    {/* FR */}
    <img src={chickenCrossing1FR} alt="" />
    {/* PL */}
    <img src={chickenCrossing1PL} alt="" />
    <img src={towersOfHanoiPL} alt="" />
    {/* GA */}
    <img src={chickenCrossing1GA} alt="" />
    <img src={towersOfHanoiGA} alt="" />
    {/* PT */}
    <img src={chickenCrossing1PT} alt="" />
    <img src={towersOfHanoiPT} alt="" />
    {/* IS */}
    <img src={towersOfHanoiIS} alt="" />
    {/* ES */}
    <img src={chickenCrossing1ES} alt="" />
    <img src={towersOfHanoiES} alt="" />
  </div >
);

export default staticImages;